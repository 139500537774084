$color-white: #fff;
$color-black: #000;
$color-grey-0: rgb(230, 230, 230);
$color-grey-1: rgb(204, 204, 204);
$color-grey-2: rgb(150, 150, 150);
$color-grey-3: rgb(110, 110, 110);
$color-grey-4: rgb(51, 51, 51);
$color-green-1: rgb(48, 129, 167);
$color-green-2: rgb(35, 95, 122);
$color-green-3: rgb(19, 50, 64);
$color-primary-dark: #28b485;
$color-secondary-dark: #ff7730;

$grid-width: 114rem;
$grid-gap-vertical: 6rem;
$grid-gap-horizontal: 10rem;

$padding-xsmall: 0.5rem;
$padding-small: 2rem;
$padding-medium: 4rem;
$padding-large: 6rem;

$margin-xsmall: 1rem;
$margin-small: 3rem;
$margin-medium: 6rem;
$margin-large: 9rem;
$margin-xlarge: 12rem;

$card-height: 35;
$card-width: 50;

$certificate-height: 35;
$certificate-width: 50;

$default-font-size: 1.6;

$border-radius: 0.5rem;

$padding-top-section-1: 5;
$padding-bottom-section-1: 5;
$padding-top-section-2: 13;
$padding-bottom-section-2: 13;

$h1-font-size-title-1: 6;
$h1-font-size-title-2: 3;
$h1-font-size-title-1-space: 1.5;
$h1-font-size-title-2-space: 2.15;
$h3-font-size: 2.6;
$p-font-size: 3;

$h2-square: 6;

$logo-box-technologies-size: 10;
$icon-skill-size: 4;
$icon-footer-size: 3;

$resize-to-mid-desk-tab-land: 0.95;
$resize-to-tab-land: 0.9;
$resize-to-mid-tab-land-tab-port: 0.85;
$resize-to-tab-port: 0.8;
$resize-to-mid-tab-port-phone: 0.75;
$resize-to-phone: 0.7;

$min-width: 25rem;
