.skills {
  &__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

  &__logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $logo-box-technologies-size * 1rem;
    height: $logo-box-technologies-size * 1rem;

    .svg-logo {
      font-size: $icon-skill-size * 1rem;

      &--local {
        width: $icon-skill-size * 1rem;
        height: $icon-skill-size * 1rem; } } }
  &__paragraph {
    font-size: $default-font-size * 1rem;
    padding-top: 0.5rem; }
  &__skew-y {
    -moz-transform: skew(-15deg);
    transform: skew(-15deg);

    &--border-left {
      border-left: 0.2rem solid rgba($color-grey-2, 0.2); }
    &--none {
      transform: skew(15deg); } }
  @include mq-manager(mid-desk-tab-land) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-mid-desk-tab-land);

        &--local {
          width: resize($icon-skill-size, $resize-to-mid-desk-tab-land);
          height: resize($icon-skill-size, $resize-to-mid-desk-tab-land); } } }
    &__paragraph {
      font-size: resize($default-font-size, $resize-to-mid-desk-tab-land);
      padding-top: 0.4rem; }
    &__skew-y {
      transform: skew(0);

      &--none {
        transform: skew(0); } } }
  @include mq-manager(tab-land) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-tab-land);

        &--local {
          width: resize($icon-skill-size, $resize-to-tab-land);
          height: resize($icon-skill-size, $resize-to-tab-land); } } }
    &__paragraph {
      font-size: resize($default-font-size, $resize-to-tab-land);
      padding-top: 0.3rem; } }
  @include mq-manager(mid-tab-land-tab-port) {
    &__logo-box {
      width: resize($logo-box-technologies-size, $resize-to-tab-land);
      height: resize($logo-box-technologies-size, $resize-to-tab-land);

      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port);

        &--local {
          width: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port);
          height: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port); } } }
    &__skew-y {
      &--border-left {
        border-left: none; } } }
  @include mq-manager(tab-port) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-tab-port);

        &--local {
          width: resize($icon-skill-size, $resize-to-tab-port);
          height: resize($icon-skill-size, $resize-to-tab-port); } } }
    &__paragraph {
      font-size: 1.1rem; } }
  @include mq-manager(mid-tab-port-phone) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-mid-tab-port-phone);

        &--local {
          width: resize($icon-skill-size, $resize-to-mid-tab-port-phone);
          height: resize($icon-skill-size, $resize-to-mid-tab-port-phone); } } } }
  @include mq-manager(phone) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-phone); }

      &--local {
        width: resize($icon-skill-size, $resize-to-phone);
        height: resize($icon-skill-size, $resize-to-phone); } } } }
