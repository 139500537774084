.btn {
  @include display-inline-flex;
  font-family: WSJ-Regular, sans-serif;
  padding: $padding-xsmall $padding-small;
  font-size: $default-font-size * 1rem;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: $border-radius;
  border: none;
  width: 17rem;
  height: 3.5rem;
  position: relative;
  align-items: flex-end;
  line-height: initial;
  transition: all 0.2s;
  cursor: pointer;

  &--white {
    background-color: $color-white;
    color: $color-black;

    &::after {
      color: $color-black;
      background-color: $color-white; } }
  &:not(:disabled) {
    &:hover {
      transform: scale(1.01) translateY(-0.4rem);
      box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

      &::after {
        background-color: $color-white;
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0; } }
    &:active,
    &:focus {
      transform: translateY(-0.1rem);
      box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2); }
    &::after {
      content: '';
      @include display-inline-flex;
      height: 100%;
      width: 100%;
      border-radius: $border-radius;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.4s;
      color: $color-black;
      background-color: transparent;
      z-index: -1; } }
  &:disabled {
    color: $color-grey-1;
    cursor: not-allowed; } }

.btn-carousel {
  position: absolute;
  top: 50%;
  width: 6rem;
  height: 6rem;
  background: rgba($color-black, 0.1);
  border-radius: $border-radius;
  font-size: 3rem;
  font-weight: bold;
  color: $color-white;
  border: none;
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);

  &--left {
    left: 1rem; }
  &--right {
    right: 1rem; }
  &:focus {
    border: 0.5rem solid rgb(111, 155, 167); } }
