.certificate {
  min-width: $certificate-width * 1rem;
  max-width: $certificate-width * 1rem;
  height: $certificate-height * 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: $default-font-size * 1rem;
  background-image: url('../../assets/images/bg_certificate.png');
  background-size: 100% 100%;
  padding: $padding-medium;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1rem 3rem rgba($color-black, 0.5);
  background-color: $color-white;

  &__link {
    text-decoration: none;
    color: $color-black; }
  &__img {
    width: 6rem;
    height: 6rem;
    margin: 1rem auto 2rem auto; }
  &__text-box {
    flex-grow: 1;
    width: 100%; }
  &__paragraph {
    color: $color-black; }
  p:nth-child(2) {
    margin-top: $margin-xsmall;
    margin-bottom: $margin-xsmall; }
  &:hover {
    transform: scale(1.3) translateY(-3rem);
    box-shadow: 0 2rem 5rem rgba($color-black, 0.4); }
  @include mq-manager(phone) {
    height: resize($card-height, $resize-to-phone);
    min-width: resize($card-width, $resize-to-phone);
    max-width: resize($card-width, $resize-to-phone);
    font-size: resize($default-font-size, $resize-to-phone);

    &__img {
      width: 3rem;
      height: 3rem;
      margin: 0.5rem auto 1rem auto; }
    &:hover {
      transform: scale(1.1) translateY(-3rem); } } }
