.carousel {
  min-width: $certificate-width * 1rem;
  max-width: $certificate-width * 1rem;
  height: $certificate-height * 1rem;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1rem 3rem rgba($color-black, 0.5);
  transition: all 0.2s ease-in-out;
  background-color: $color-white;

  &__slide {
    transition: all 0.5s;
    width: 100%;
    height: 100%; }

  &__img {
    width: $certificate-width * 1rem;
    height: $certificate-height * 1rem; }
  &:hover {
    transform: scale(1.3) translateY(-3rem);
    box-shadow: 0 2rem 5rem rgba($color-black, 0.4);

    & > button {
      background: rgba($color-black, 0.3); }
    & > .dots > .dots__dot {
      background: rgba($color-black, 0.4); } }
  @include mq-manager(phone) {
    height: resize($card-height, $resize-to-phone);
    min-width: resize($card-width, $resize-to-phone);
    max-width: resize($card-width, $resize-to-phone);

    &__img {
      width: resize($card-width, $resize-to-phone);
      height: resize($card-height, $resize-to-phone); }
    &:hover {
      transform: scale(1.1) translateY(-3rem); } } }
