*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
  min-width: $min-width;

  @include mq-manager(phone) {
    font-size: 30%; } // 1rem = 4.8px, 4.8/16 = 30%
  @include mq-manager(tab-port) {
    font-size: 50%; } // 1rem = 8px, 8/16 = 50%
  @include mq-manager(tab-land) {
    font-size: 56.25%; } // 1rem = 9px, 9/16 = 56.25%
  @include mq-manager(desk) {
    font-size: 62.5%; } } // 1rem = 10px; 10px/16px = 62.5%

body {
  min-width: $min-width;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  color: $color-white; }

.App {
  position: relative; }
