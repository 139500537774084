.journal-box {
  color: $color-black;
  background-color: $color-grey-1;
  width: 45rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 0.5rem;
  padding: 1.5rem;
  position: relative;

  &__heading-primary {
    font-family: WSJ-Bold, sans-serif;
    text-transform: uppercase;
    width: 100%;
    white-space: nowrap;
    font-size: 3.15rem;
    filter: blur(0.08rem); }

  &__heading-secondary {
    font-family: WSJ-Bold, sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0.5rem;
    filter: blur(0.08rem); }
  &__header {
    display: flex;
    flex-direction: column;
    filter: blur(0.125rem);

    &__content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      &:last-child {
        border-top: 0.1rem solid $color-black;
        border-bottom: 0.1rem solid $color-black;
        font-size: 4.5px; } } }
  &__content {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    margin-top: 1rem;

    &__heading {
      text-transform: uppercase;
      text-align: center; }
    &__photo {
      width: 25rem;
      height: 25rem;
      float: left;
      margin: 0 1rem 0 0;
      filter: grayscale(35%); }
    &__paragraph {
      font-size: 0.8rem;

      &--blur {
        filter: blur(0.125rem); } } }
  &,
  &::before,
  &::after {
    border-radius: $border-radius;
    box-shadow: 1rem 1rem 2rem rgba($color-black, 0.5); }
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 95%;
    width: 99%;
    background-color: #eee; }
  &::before {
    right: 0rem;
    top: 1rem;
    transform: rotate(0deg);
    background-color: $color-grey-1;
    z-index: -1; }
  &::after {
    top: 1.8rem;
    right: -0.4rem;
    transform: rotate(1deg);
    z-index: -2; }
  @include mq-manager(phone) {
    transform: scale(0.7); } }

.info-box {
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 0.2rem; }
  &__text {
    font-weight: bold;
    margin-right: 0.2rem; }
  &__symbol {
    margin-right: 0.2rem;
    margin-left: 0.2rem;

    &--up {
      color: green; }
    &--down {
      color: red; } } }
