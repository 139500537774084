.icon {
  &__footer {
    font-size: $icon-footer-size * 1rem;
    width: $icon-footer-size * 1rem;
    height: $icon-footer-size * 1rem;

    &--local {
      height: $icon-footer-size * 1rem; }
    &--resume {
      height: $icon-footer-size * 2rem; } }
  &__project {
    &--resume {
      height: $icon-skill-size * 2.5rem; } } }
