.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  min-height: 7rem;
  width: 100%;
  text-transform: uppercase;
  font-family: WSJ-Bold, sans-serif;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  box-shadow: none;
  color: $color-white;

  &--visible {
    background-color: white;
    color: $color-black;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2); }
  &--active {
    border-bottom: 0.5rem solid $color-green-1; }
  &__logo {
    font-family: Allura-Regular, sans-serif;
    text-transform: capitalize;
    font-size: 4rem;
    display: inherit;
    text-decoration: none;
    margin: 0 $margin-small;
    justify-content: center;
    align-items: center;
    padding: 0 $padding-small;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      color: $color-green-2;
      text-shadow: 0 0.3rem 0.5rem rgba($color-black, 0.1); } }
  &__list {
    display: inherit;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    flex-grow: 1;
    justify-content: flex-end;
    min-height: 7rem;

    &--active,
    &--hidden {
      flex-direction: row; } }
  &__item {
    margin: 0;
    padding: 0;
    height: 100%;
    cursor: pointer; }
  &__link {
    text-decoration: none;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 $padding-small;

    &:hover {
      color: $color-white;
      background-color: rgba($color-green-3, 0.9); } }
  &__btn {
    opacity: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: none;
    justify-content: center;
    align-items: center;
    min-width: 5rem;
    min-height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: 0.3rem solid $color-green-2;
    border-radius: $border-radius;

    &--fade {
      border: 0.3rem solid rgba($color-grey-2, 0.5); }

    &--fade > &__burger {
      background-color: rgba($color-grey-2, 0.5);

      &::before,
      &::after {
        content: '';
        background-color: rgba($color-grey-2, 0.5); } }
    &__burger {
      width: 2.5rem;
      height: 0.3rem;
      background-color: $color-green-2;
      border-radius: $border-radius;
      transition: all 0.5s ease-in-out;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 2.5rem;
        height: 0.3rem;
        background-color: $color-green-2;
        border-radius: $border-radius;
        transition: all 0.5s ease-in-out; }

      &::before {
        transform: translateY(-0.8rem); }
      &::after {
        transform: translateY(0.8rem); } }
    &--open {
      border: 0.3rem solid $color-green-2; }
    &--open > &__burger {
      background-color: transparent;

      &::before {
        top: 50%;
        transform: rotate(-135deg);
        background-color: $color-green-2; }
      &::after {
        top: 50%;
        transform: rotate(135deg);
        background-color: $color-green-2; } } } }

@include mq-manager(mid-tab-land-tab-port) {
  .navbar {
    justify-content: center;

    &--active {
      border-bottom: 0.2rem solid $color-green-1; }
    &--column {
      flex-direction: column;
      background-color: $color-white;
      color: $color-black;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.2); }
    &__logo {
      margin: 0;
      padding: 0;
      flex-grow: 0; }
    &__list {
      &--visible {
        display: flex;
        flex-direction: column; }
      &--hidden {
        display: none; } }
    &__item {
      height: 4.5rem;
      width: 80%; }
    &__btn {
      display: flex;
      opacity: 1;
      visibility: visible; } } }
