.card {
  perspective: 150rem;
  position: relative;
  height: $card-height * 1rem;
  width: $card-width * 1rem;
  display: inline-flex;

  &__side {
    height: $card-height * 1rem;
    width: 100%;
    transition: all 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    border-radius: $border-radius;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
    overflow: hidden;

    &--front {
      background-color: $color-grey-1; }
    &--back {
      transform: rotateY(180deg);
      background-image: linear-gradient(to right bottom, $color-green-2, $color-green-3); } }
  &:hover &__side--front {
    transform: rotateY(-180deg); }
  &:hover &__side--back {
    transform: rotateY(0); }
  &__picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border-radius: $border-radius; }
  &__front-box {
    position: absolute;
    bottom: 1.5rem;
    right: 0;
    overflow: hidden;

    & > * {
      margin-right: 1.5rem; } }
  &__back-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 2rem; } }
  @include mq-manager(phone) {
    height: resize($card-height, $resize-to-phone);
    width: resize($card-width, $resize-to-phone);

    &__side {
      height: resize($card-height, $resize-to-phone); }
    &__back-box {
      a {
        margin-top: 1.5rem; } } } }
