@font-face {
  font-family: 'WSJ-Regular';
  font-weight: 400;
  src: local('WSJ-Regular'), url('../../assets/fonts/OldStandardTT-Regular.ttf') format('opentype'); }

@font-face {
  font-family: 'WSJ-Bold';
  font-weight: 700;
  src: local('WSJ-Bold'), url('../../assets/fonts/OldStandardTT-Bold.ttf') format('opentype'); }

@font-face {
  font-family: 'Allura-Regular';
  font-weight: 400;
  src: local('Allura-Regular'), url('../../assets/fonts/Allura-Regular.ttf') format('opentype'); }
