.footer {
  &__navigation {
    border-top: 0.1rem solid $color-grey-1;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    min-height: 7rem;
    flex-direction: column; }
  &__list {
    list-style: none;
    font-size: 1.4rem;
    display: flex; }
  &__item {
    &:not(:last-child) {
      margin-right: 1rem; } }
  &__resume-box {
    display: flex;
    justify-content: center;
    margin-bottom: $margin-medium; }
  &__resume-paragraph {
    font-size: 1.5rem;
    line-height: 0.5rem; }
  &__resume-link {
    text-decoration: none;
    color: $color-white;
    transition: all 0.2s;

    &:hover {
      transform: scale(1.2); } }
  @include mq-manager(mid-tab-land-tab-port) {
    &__navigation {
      display: flex;
      flex-grow: 1; }
    &__list {
      justify-content: center; } } }
