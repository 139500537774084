.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(100deg, rgba($color-white, 0.5) 0%, rgba($color-white, 0.5) 50%, transparent 50%), url('../../assets/images/bg_form.jpg');
  background-size: cover;
  box-shadow: 0rem 2rem 5rem rgba($color-black, 0.5);
  margin-top: $margin-small;
  margin-bottom: $margin-large;
  padding: $padding-medium;

  &__group {
    &:not(:last-child) {
      margin-bottom: $margin-xsmall; }
    &--message {
      display: flex;
      flex-direction: column;
      height: 100%; }
    &--ctrl {
      text-align: right; } }
  &__input {
    font-size: $default-font-size * 1rem;
    font-family: inherit;
    color: $color-black;
    padding: 1rem 1.5rem;
    border-radius: $border-radius;
    background-color: rgba($color-white, 0.7);
    border: none;
    border-bottom: 0.3rem solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;
    resize: none;

    &--message {
      height: 100%; }
    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 0.3rem solid $color-primary-dark; }
    &:focus:invalid {
      border-bottom: 0.3rem solid $color-secondary-dark; }
    &::-webkit-input-placeholder {
      color: $color-grey-2; }
    &:valid {
      border-bottom: 0.3rem solid $color-primary-dark; }
    &:invalid {
      border-bottom: 0.3rem solid $color-secondary-dark; } }
  &__label {
    font-size: $default-font-size * 1rem;
    font-weight: 700;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    text-align: left;
    text-shadow: 0.3rem 0.3rem 0.5rem rgba($color-black, 0.5); }
  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem); }
  @include mq-manager(mid-tab-land-tab-port) {
    &__input {
      &--message {
        height: 15rem; } }
    &__group {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &--message {
        margin-top: $margin-xsmall; }
      &--ctrl {
        align-items: flex-end; } } }
  @include mq-manager(tab-land) {
    width: 90%;
    height: 90%; }
  @include mq-manager(phone) {
    margin-top: $margin-small;
    margin-bottom: $margin-small; } }
