.row {
  max-width: $grid-width;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  @include clearfix;
  .col-1-of-1 {
    width: 100%; }
  .col-1-of-2 {
    width: calc(100% / 2); }
  .col-1-of-3 {
    width: calc(100% / 3); }
  .col-2-of-3 {
    width: calc(2 * 100% / 3); }
  .col-1-of-4 {
    width: calc(100% / 4); }
  .col-2-of-4 {
    width: calc(2 * 100% / 4); }
  .col-3-of-4 {
    width: calc(3 * 100% / 4); }
  @include mq-manager(mid-tab-land-tab-port) {
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 2rem; }
    .col-1-of-2 {
      min-width: $min-width;
      width: 100%; } } }

.row-gap {
  max-width: $grid-width;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: $grid-gap-vertical;
    @include clearfix; }
  div[class^='col-'] {
    float: left;
    font-size: 2rem;

    &:not(:last-child) {
      margin-right: $grid-gap-horizontal; } }
  .col-1-of-1 {
    width: 100%; }
  .col-1-of-2 {
    width: calc((100% - #{$grid-gap-horizontal}) / 2); }
  .col-1-of-3 {
    width: calc((100% - 2 * #{$grid-gap-horizontal}) / 3); }
  .col-2-of-3 {
    width: calc((2 * ((100% - 2 * #{$grid-gap-horizontal})) / 3) + #{$grid-gap-horizontal}); }
  .col-1-of-4 {
    width: calc((100% - 3 * #{$grid-gap-horizontal}) / 4); }
  .col-2-of-4 {
    width: calc((2 * ((100% - 3 * #{$grid-gap-horizontal})) / 4) + #{$grid-gap-horizontal}); }
  .col-3-of-4 {
    width: calc((3 * ((100% - 3 * #{$grid-gap-horizontal})) / 4) + 2 * #{$grid-gap-horizontal}); }

  @include mq-manager(mid-tab-land-tab-port) {
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0; }
    div[class^='col-'] {
      float: left;
      font-size: 2rem;
      width: 100%;

      &:not(:last-child) {
        margin-right: 0; } }
    .col-1-of-2--footer {
      width: 80% !important; } } }
