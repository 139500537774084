.header {
  &__bg {
    height: 100%;
    width: 100%;
    background-image: url('../../assets/images/bg_header.jpg');
    background-size: cover;
    background-position: center;
    transform: scale(1.1);
    position: absolute;
    top: 0;
    left: 0; }

  &__text-box {
    @include abs-center;
    display: flex;
    text-align: center;
    flex-direction: column;
    width: 100%; }

  @include mq-manager(phone) {
    &__bg {
      filter: grayscale(70%); } } }
