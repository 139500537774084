.heading-primary {
  color: $color-white;
  text-transform: uppercase;

  &__text-title-1 {
    font-family: WSJ-Bold, sans-serif;
    font-weight: 400;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-size: $h1-font-size-title-1 * 1rem;
    letter-spacing: $h1-font-size-title-1-space * 1rem;
    animation: move-in-left 1s ease-in-out 1s;
    animation-fill-mode: backwards; }
  &__text-title-2 {
    font-family: WSJ-Bold, sans-serif;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    font-size: $h1-font-size-title-2 * 1rem;
    letter-spacing: $h1-font-size-title-2-space * 1rem;
    animation: move-in-right 1s ease-out 1s;
    animation-fill-mode: backwards; } }

.heading-secondary {
  color: $color-black;
  text-transform: uppercase;

  &__text-title-1 {
    font-family: WSJ-Bold, sans-serif;
    display: inline-flex;
    transition: transform 0.3s ease-in-out;
    border-radius: $border-radius;
    margin-bottom: $margin-large;

    & > span {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      width: $h2-square * 1rem;
      height: $h2-square * 1rem;
      line-height: $h2-square * 1rem;
      background-color: $color-grey-1;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0.6rem 5rem rgba($color-black, 0.3); }
    &:hover {
      transform: rotate((35deg)), translateY(-50%);

      span {
        &:nth-child(odd) {
          transform: skewY(15deg);
          color: $color-grey-3;
          background-color: $color-grey-0; }
        &:nth-child(even) {
          transform: skewY(-15deg);
          color: $color-black;
          background-color: $color-grey-2; } } } }
  &__text-title-2 {
    font-family: WSJ-Bold, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center; }
  &--black {
    padding: 0 $padding-small;
    border-bottom: 1px solid $color-black; } }

.heading-tertiary {
  color: $color-white;
  text-transform: uppercase;
  font-size: $h3-font-size * 1rem;
  font-weight: 400;
  font-family: WSJ-Regular, sans-serif;
  margin-bottom: $margin-small;
  display: flex;
  justify-content: center;
  align-items: center;

  &__animated {
    animation: move-in-bottom 1s ease-out 1.3s;
    animation-fill-mode: backwards;
    font-style: italic;
    flex-wrap: wrap;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 $padding-small; }
  &--white {
    letter-spacing: 0.5rem;
    background-image: linear-gradient(to right, rgba($color-green-1, 0.8), rgba($color-green-3, 0.8));
    width: 90%;
    border-radius: $border-radius;
    align-items: center;
    height: 4rem;
    margin-top: $margin-small;
    margin-bottom: $margin-small; }
  @include mq-manager(phone) {
    font-size: resize($h3-font-size, 0.75); }
  @include mq-manager(mid-tab-land-tab-port) {
    margin-top: $margin-small; } }

.heading-quaternary {
  color: $color-white;
  text-transform: uppercase;
  font-family: WSJ-Regular, sans-serif;
  letter-spacing: 0.5rem;
  display: inline-flex !important;
  align-items: center;
  height: 4rem;

  &--bg-green {
    background-image: linear-gradient(to right, rgba($color-green-1, 0.8), rgba($color-green-3, 0.8)); }
  &--black {
    font-family: WSJ-Bold, sans-serif;
    color: $color-black; } }

.paragraph {
  font-family: WSJ-Regular, sans-serif;
  color: $color-black;
  font-size: $p-font-size * 1rem;

  &--animated {
    &:hover {
      transform: scale(1.1) skewY(-2deg);
      color: rgb(103, 190, 214);
      text-shadow: 0 0.3rem 0.5rem rgba($color-white, 0.5); } }
  &__footer {
    display: flex;
    font-size: 1rem;
    display: inline-block;
    transition: all 0.2s;

    &--link {
      color: $color-white;
      text-decoration: none; }
    &--name {
      font-family: sans-serif;
      font-size: 1.2rem; } } }

.paragraph-fade-in {
  padding: $padding-medium; }

.tooltip {
  position: relative;
  display: inline-block;

  &__tooltip-text {
    visibility: hidden;
    width: 12rem;
    background-color: $color-grey-3;
    color: $color-white;
    text-align: center;
    border-radius: 0.6rem;
    padding: 0.5rem 0;
    position: absolute;
    bottom: 125%;
    left: 50%;
    z-index: 1;
    margin-left: -6rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -0.5rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: $color-grey-2 transparent transparent transparent; } }
  &:hover &__tooltip-text {
    visibility: visible;
    opacity: 1;

    &--1 {
      bottom: 110%;
      font-size: 1.2rem; } }
  &:hover &__tooltip-text-1 {
    visibility: visible;
    opacity: 1; } }

@include mq-manager(mid-desk-tab-land) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-tab-land); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-mid-desk-tab-land);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-mid-desk-tab-land);

      &__text-title-2 {
        font-size: resize($h1-font-size-title-2, $resize-to-mid-desk-tab-land);
        letter-spacing: resize($h1-font-size-title-2-space, $resize-to-mid-desk-tab-land); } } }
  .heading-secondary {
    & > span {
      width: resize($h2-square, $resize-to-mid-desk-tab-land);
      height: resize($h2-square, $resize-to-mid-desk-tab-land);
      line-height: resize($h2-square, $resize-to-mid-desk-tab-land); } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-mid-desk-tab-land); } } }

@include mq-manager(tab-land) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-tab-land); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-tab-land);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-tab-land);

      &__text-title-2 {
        font-size: resize($h1-font-size-title-2, $resize-to-tab-land);
        letter-spacing: resize($h1-font-size-title-2-space, $resize-to-tab-land); } } }
  .heading-secondary {
    & > span {
      width: resize($h2-square, $resize-to-tab-land);
      height: resize($h2-square, $resize-to-tab-land);
      line-height: resize($h2-square, $resize-to-tab-land); } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-tab-land); } } }

@include mq-manager(mid-tab-land-tab-port) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-mid-tab-land-tab-port); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-mid-tab-land-tab-port);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-mid-tab-land-tab-port); }
    &__text-title-2 {
      font-size: resize($h1-font-size-title-2, $resize-to-mid-tab-land-tab-port);
      letter-spacing: resize($h1-font-size-title-2-space, $resize-to-mid-tab-land-tab-port); } }
  .heading-secondary {
    & > span {
      width: resize($h2-square, $resize-to-mid-tab-land-tab-port);
      height: resize($h2-square, $resize-to-mid-tab-land-tab-port);
      line-height: resize($h2-square, $resize-to-mid-tab-land-tab-port); } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-mid-tab-land-tab-port); } } }

@include mq-manager(tab-port) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-tab-port); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-tab-port);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-tab-port); }
    &__text-title-2 {
      font-size: resize($h1-font-size-title-2, $resize-to-tab-port);
      letter-spacing: resize($h1-font-size-title-2-space, $resize-to-tab-port); } }
  .heading-secondary {
    & > span {
      width: resize($h2-square, $resize-to-tab-port);
      height: resize($h2-square, $resize-to-tab-port);
      line-height: resize($h2-square, $resize-to-tab-port); } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-tab-port); } } }

@include mq-manager(mid-tab-port-phone) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-mid-tab-port-phone); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-mid-tab-port-phone);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-mid-tab-port-phone); }
    &__text-title-2 {
      font-size: resize($h1-font-size-title-2, $resize-to-mid-tab-port-phone);
      letter-spacing: resize($h1-font-size-title-2-space, $resize-to-mid-tab-port-phone); } }
  .heading-secondary {
    & > span {
      width: resize($h2-square, $resize-to-mid-tab-port-phone);
      height: resize($h2-square, $resize-to-mid-tab-port-phone);
      line-height: resize($h2-square, $resize-to-mid-tab-port-phone); } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-mid-tab-port-phone); } } }

@include mq-manager(phone) {
  .paragraph {
    font-size: resize($p-font-size, $resize-to-phone); }
  .heading-primary {
    &__text-title-1 {
      font-size: resize($h1-font-size-title-1, $resize-to-phone);
      letter-spacing: resize($h1-font-size-title-1-space, $resize-to-phone); }
    &__text-title-2 {
      font-size: resize($h1-font-size-title-2, $resize-to-phone);
      letter-spacing: resize($h1-font-size-title-2-space, $resize-to-phone); } }
  .heading-secondary {
    &__text-title-1 {
      margin-bottom: $margin-small;

      & > span {
        width: resize($h2-square, $resize-to-phone);
        height: resize($h2-square, $resize-to-phone);
        line-height: resize($h2-square, $resize-to-phone); } } }
  .heading-tertiary {
    &__quotes {
      font-size: resize($h3-font-size, $resize-to-phone * 0.9);
      font-family: WSJ-Bold, sans-serif; }
    &--white {
      margin-top: $margin-xsmall;
      margin-bottom: $margin-xsmall; } } }
