.modal {
  height: 100%;
  width: 100%;
  min-width: $min-width;
  position: fixed;
  top: 0;
  left: 0;
  color: $color-black;
  z-index: 10;

  &--true {
    background-color: rgba($color-black, 0.6);
    opacity: 1;
    visibility: unset; }
  &--false {
    visibility: hidden;
    opacity: 0; }
  &__content {
    @include abs-center;
    width: 110rem;
    height: 60rem;
    max-height: 70rem;
    background-color: $color-white;
    display: flex;
    border-radius: $border-radius;
    background-color: $color-grey-0;
    overflow: hidden;
    box-shadow: 0 2rem 4rem rgba($color-black, 0.7); }
  &__left {
    width: 55%;
    padding: $padding-medium;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  &__right {
    width: 45%;
    padding: $padding-medium;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__link {
    text-decoration: none;
    display: flex;
    transition: all 0.2s ease-in-out;
    box-shadow: 0.5rem 0.5rem 1rem rgba($color-black, 0.5);
    border-radius: $border-radius;
    overflow: hidden;

    &:hover {
      transform: scale(1.05);
      box-shadow: 1rem 1rem 2rem rgba($color-black, 0.5); } }
  &__photo {
    width: 100%; }
  &__close {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    font-size: 3rem;
    text-decoration: none;
    display: inline-block;
    transition: all 0.2s;
    line-height: 1;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: $border-radius;
    background-color: rgba($color-black, 0.1);
    display: flex;
    justify-content: center;
    align-content: center;
    color: $color-green-3;

    &:hover {
      color: $color-secondary-dark;
      background-color: rgba($color-black, 0.3); } }
  &__text-box {
    font-family: WSJ-Regular, sans-serif;
    font-size: $default-font-size * 1rem;
    text-align: justify;
    height: 100%; }
  &__technologies-box {
    margin-top: $margin-xsmall;
    max-height: 25%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  &__logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    font-size: 3.5rem;

    .svg-logo {
      font-size: $icon-skill-size * 1rem;
      width: $icon-skill-size * 1rem;
      height: $icon-skill-size * 1rem;

      &--local {
        height: $icon-skill-size * 1rem; } }
    &:not(:first-child) {
      margin-left: 1rem; } }
  @include mq-manager(mid-tab-land-tab-port) {
    &__content {
      width: 100%;
      height: 70%;
      flex-direction: column;
      padding: $padding-medium; }
    &__left {
      width: 100%;
      height: 50%;
      padding: 0; }
    &__right {
      width: 100%;
      height: 100%;
      padding: 0;
      margin-top: $margin-small; }
    &__link {
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: $border-radius; }
    &__photo {
      width: 100%;
      border-radius: $border-radius;
      object-fit: scale-down; }
    &__text-box {
      margin-top: $margin-xsmall;
      height: 100%; }
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-mid-desk-tab-land);
        width: resize($icon-skill-size, $resize-to-mid-desk-tab-land);
        height: resize($icon-skill-size, $resize-to-mid-desk-tab-land);

        &--local {
          height: resize($icon-skill-size, $resize-to-mid-desk-tab-land); } } }
    &__logo-box &:not(:last-child) {
      margin-left: 0.75rem; } }
  @include mq-manager(tab-land) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-tab-land);
        width: resize($icon-skill-size, $resize-to-tab-land);
        height: resize($icon-skill-size, $resize-to-tab-land);

        &--local {
          height: resize($icon-skill-size, $resize-to-tab-land); } } } }
  @include mq-manager(mid-tab-land-tab-port) {
    &__logo-box {
      .svg-logog {
        font-size: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port);
        width: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port);
        height: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port);

        &--local {
          height: resize($icon-skill-size, $resize-to-mid-tab-land-tab-port); } } } }
  @include mq-manager(tab-port) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-tab-port);
        width: resize($icon-skill-size, $resize-to-tab-port);
        height: resize($icon-skill-size, $resize-to-tab-port);

        &--local {
          height: resize($icon-skill-size, $resize-to-tab-port); } } } }
  @include mq-manager(mid-tab-port-phone) {
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-mid-tab-port-phone);
        width: resize($icon-skill-size, $resize-to-mid-tab-port-phone);
        height: resize($icon-skill-size, $resize-to-mid-tab-port-phone);

        &--local {
          height: resize($icon-skill-size, $resize-to-mid-tab-port-phone); } } } }
  @include mq-manager(phone) {
    &__content {
      padding: $padding-small; }
    &__left {
      height: 40%;
      padding: 0; }
    &__right {
      height: 60%;
      margin-top: 0;
      padding-top: 1.5rem; }
    &__text-box {
      font-size: resize($default-font-size, $resize-to-mid-desk-tab-land);
      margin-top: 0; }
    &__text {
      font-size: resize($default-font-size, $resize-to-tab-land); }
    &__technologies-box {
      max-height: 50%; }
    &__logo-box {
      .svg-logo {
        font-size: resize($icon-skill-size, $resize-to-phone);
        width: resize($icon-skill-size, $resize-to-phone);
        height: resize($icon-skill-size, $resize-to-phone);

        &--local {
          height: resize($icon-skill-size, $resize-to-phone); } } }
    &__logo-box &:not(:last-child) {
      margin-left: 0.5rem; } } }

.modal-msg {
  position: fixed;
  bottom: 0;
  right: 5%;
  color: $color-black;
  width: 40rem;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  overflow: hidden;
  text-decoration: none;
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
  box-shadow: 0 1rem 2rem rgba($color-black, 0.3);
  z-index: 3;

  &__header {
    background-color: $color-green-2;
    display: flex; }
  &__title {
    font-family: WSJ-Bold, sans-serif;
    text-transform: capitalize;
    padding-right: $padding-medium;
    flex-grow: 1;
    font-size: $default-font-size * 1rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    align-items: flex-end;
    color: $color-white; }
  &__close {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    font-size: 2rem;
    text-decoration: none;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: $border-radius;
    background-color: rgba($color-white, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $color-white;
    transition: all 0.2s;

    &:hover {
      color: $color-secondary-dark;
      background-color: rgba($color-white, 0.2); } }
  &__body {
    display: flex;
    background-color: $color-grey-1;
    height: 7rem;
    padding: $padding-xsmall; }
  &__msg {
    height: 100%;
    width: 100%;
    font-size: 1.7rem;
    font-family: WSJ-Regular, sans-serif;
    display: inline-block;
    text-align: center; }
  &:hover {
    opacity: 1; }
  &--visible-false {
    opacity: 0;
    display: none; }
  &--visible-true {
    opacity: 1;
    display: flex; }

  @include mq-manager(phone) {
    position: fixed;
    bottom: 0;
    right: 0%;
    width: 100%;

    &__body {
      height: 8.5rem; } } }
